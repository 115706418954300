import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
// import Text from './BioCard/Text'
import Image from './Hero/Image'

const useStyles = makeStyles((theme) => ({
    footerWrapper: {
        // color: theme.palette.secondary.light,
        background: theme.palette.primary.dark,
        width: '100vw',
        height: '30vh',
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'space-evenly',
        padding: '0'
      },
     logo: {
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
        paddingTop: '1vh'
        
     },
     footerInfo: {
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
    },
    text: {
         fontColor: '#FFFFFF'

     }
    }
));
export default function AWSTFooter() {
    const classes = useStyles();
    return (
        <div className={classes.footerWrapper}>
            {/* <div className={classes.footerInfo}> */}
            {/* <Text className={classes.text} data={'Naptown Media, Inc.'}/> */}
            {/* <Text className={classes.text} data={'Indianapolis, Indiana'}/> */}
            {/* <Text className={classes.text} data={'headerText.large'}/> */}
            {/* </div> */}
            <div className={classes.logo}>
            <Image image={'./images/ntlogo_h.png'} />            
            </div>
        </div>
    )
}

