import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

let sponsors = [
    'Christopher Kojm',
    'Michael Hurley',
    'Barbara Grewe',
    'Daniel Marcus',
    'John Farmer',
]

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',
    },
    header: {
        width: '100%',
        height: 'auto',
        fontSize: '34px',
        paddingTop: '4vw',
        padding: '2vw',
        textAlign:'center',
    },
    textContainer: {
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',
        width: '70vw',
        margin: 'auto',
        fontSize: '22px',
    },
    list: {
        // padding: 0,
        marginTop: '.5vw',
        marginBottom: '.5vw'
    }
}
))


export default function StaffAdvisors() {
    const classes = useStyles()
    return (
        <div id={'advisors'} className={classes.container}>
         <div className={classes.header}>9/11 Commission Staff<br></br> Serving as Project Advisors</div>
        <div className={classes.textContainer}>
            {
                sponsors.map((sponsor, i) => {
                    return (
                        <p key={i} className={classes.list}>{sponsor}</p>
                        )
                    })
                }
        </div>
       
        </div>
    )
}
