import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Text from './BioCard/Text'

const data = {
    text2: 'The “Are We Safer Today?” project will also produce a civics curriculum and learning app in association the Center for Representative Government at Indiana University,  Funded by the Library of Congress, the curriculum will be available nationwide through the Indiana University system.',
    text: 'The "Are We Safer Today?" project will produce a civics curriculum and learning app in association with the Center for Representative Government at Indiana University. Funded by the Library of Congress, the curriculum will be available nationwide through the Indiana University system.'
}
const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',
    },
    header: {
        width: '100%',
        height: 'auto',
        fontSize: '34px',
        paddingTop: '4vw',
        padding: '2vw',
        textAlign:'center',
    },
    textContainer: {
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',
        width: '60vw',
        margin: 'auto',
        fontSize: '22px',
    },
}
))

export default function Civics() {
    const classes = useStyles()
    return (
        <div id={'civics'} className={classes.container}>
        <div className={classes.header}>Civics Curriculum</div>
        <div>
            <Text theme={classes.textContainer}>{data.text}</Text>
        </div>
    </div>
    )
}
