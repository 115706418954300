import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Image from './BioCard/Portrait'

let picHash = [
    [{
        position: 1,
        imagePath: './images/band1-1.jpg',
    },
    {
        position: 2,
        imagePath: './images/band1-2.jpg',
    },
    {
        position: 3,
        imagePath: './images/band1-3.jpg',
    }],
    [{
        position: 1,
        imagePath: './images/band2-1.jpg',
    },
    {
        position: 2,
        imagePath: './images/band2-2.jpg',
    },
    {
        position: 3,
        imagePath: './images/band2-3.jpg',
    }],
    [{
        position: 1,
        imagePath: './images/band3-1.jpg',
    },
    {
        position: 2,
        imagePath: './images/band3-2.jpg',
    },
    {
        position: 3,
        imagePath: './images/band3-3.jpg',
    }],
    [{
        position: 1,
        imagePath: './images/band4-1.jpg',
    },
    {
        position: 2,
        imagePath: './images/band4-2.jpg',
    },
    {
        position: 3,
        imagePath: './images/band4-3.jpg',
    }],
    [{
        position: 1,
        imagePath: './images/podcastTitle.png',
    },
    {
        position: 2,
        imagePath: './images/podcastHsts.png',
    }],

]

const useStyles = makeStyles((theme) => ({
    [theme.breakpoints.down('sm')]:{
       teamWrapper: {
            display: 'none',
        },
    },
    [theme.breakpoints.between('sm','lg')]: {
        teamWrapper: {
            display: 'flex',
            flexFlow: 'row',
            height: 'auto',
            justifyContent: 'space-evenly',
            width: '100vw',
            margin: 'auto',
            alignItems: 'center',
            padding: '2vw'
        },
        image: {
            padding: '1vw',
            display:'block',
            margin:'auto',
            width: '90%',
            maxWidth: '400px',
    },
    },
   [theme.breakpoints.up('lg')]: {
        teamWrapper: {
            display: 'flex',
            flexFlow: 'row',
            height: 'auto',
            justifyContent: 'space-evenly',
            width: '100vw',
            margin: 'auto',
            alignItems: 'center',
            padding: '2vw'
        },
        image: {
            padding: '1vw',
            display:'block',
            margin:'auto',
            width: '500px',
            // maxWidth: '600px',
        },
    },
}));

export default function ImageTrio(props) {
    const classes = useStyles();
    return (
        <div className={classes.teamWrapper}>
          {
              picHash[props.band].map((picture, index) => {
                  return (<Image key={index} imgTheme={classes.image} image={picture.imagePath} />)
              })
          }
        </div>
    )
}
