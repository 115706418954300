import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Text from './Text'
import Image from './Image'
import Box from '@material-ui/core/Box'

const headerText = {
    leadin: 'Are We Safer Today?',
    desc:['Documentary Film','Oral Histories','Podcast','Civics Curriculum'],
    summary: '',
    ids: ['documentary', 'history', 'podcast','civics' ]
}
const useStyles = makeStyles((theme) => ({
    link: {
        // background: 
        textDecoration: 'none',
        color: '#e4e4e4',
        "&:hover, &:focus": {
            color: '#ff0000'
          },
        
    },
    container: {
        backgroundImage: 'url(./images/meeting_bg.png)',
        // backgroundPosition: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        height: '100vh',
        width: '100vw',
        position: 'absolute',
        top: 0,
        left: 0,
        overflow: 'hidden'
    },
    heroLogo: {
        margin: 'auto',
        padding: '2vh',
        width: '10rem',
        position: 'absolute',
        top: 55,
        left: 5,
        zIndex: 110,
    },
    heroPicture: {
        width: '100vw',
        maxWidth: 1150,
        minWidth: 1150,
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        
    },
    textTitle: {
        background: theme.palette.primary.dark,
        color: '#ffffff',
        margin: 'auto',
        position: 'absolute',
        left: '50%',
        top: '40%',
        transform: 'translateX(-50%)',
        width: '70vw',
        zIndex: 120,
    },
    textDesc: {
        display: 'flex',
        flexFlow: 'column wrap',
        background: theme.palette.primary.dark,
        "&:hover, &:focus": {
            background: theme.palette.primary.dark
          },
        color: '#ffffff',
        margin: 'auto',
        position: 'absolute',
        right: '0%',
        bottom: '10%',
        width: '35vw',
        zIndex: 120,
        fontSize: '1.25em',
        textAlign: 'center',
     
    },
    titleInternal: {
        fontSize: '5vw',
        textAlign: 'center',
        margin: '0, auto',
        zIndex: 120,
    },
    descInternal: {
    },
    topWrapper: {
        width: '100vw',
    },
    [theme.breakpoints.down('sm')]: {
        textTitle: {
            width: '90vw',
        },
        titleInternal: {
            fontSize: '8vw',
            textAlign: 'center',
            margin: '0, auto',
            zIndex: 120,
        },
        textDesc: {
            
            width: '70vw',
            fontSize: '1em',
        
        }
}
}
));

const clickHandle = (e) => {
    console.log(e.target.innerHTML)
}
export default function Hero() {
    const classes = useStyles()
    return (
        <div className={classes.container}>
                <Image image={'./images/awst_logo.png'} theme={classes.heroLogo} />
                <Box boxShadow={12}  > 
                    <Image image={'./images/meeting.jpg'} theme={classes.heroPicture} />
                </Box>
                <div className={classes.topWrapper}>
                    <Box boxShadow={13} className={classes.textTitle}> 
                        <Text theme={classes.titleInternal} data={headerText.leadin}/>
                    </Box>
                    <Box boxShadow={13} className={classes.textDesc}> 
                        {
                            headerText.desc.map( (descr, i) => {
                                return (
                                    <a key={i} className={classes.link} href={`#${headerText.ids[i]}`}>
                                        <p key={i} id={i} onClick={clickHandle} theme={classes.descInternal}>{descr}</p>
                                    </a>
                                )
                            })
                        }
                    </Box>
                </div>
        </div>
    )
}
