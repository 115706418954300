import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Hero from './Hero/Hero'
// import Support from './Support'
import Team from './Team'
import Video from './Video/VideoCard'
import ProjectOverview from './Video/ProjectOverview'
import AWSTFooter from './AWSTFooter'
import OralHistory from './OralHistory';
import Civics from './CivicsCurriculum';
import ProjectSponsors from './ProjectSponsors';
import Commisioners from './Commissioners';
import StaffAdvisors from './StaffAdvisors';
import ImageTrio from './ImageTrio';
import Podcast from './Podcast';

const useStyles = makeStyles((theme) => ({
    container: {
        background: '#2d3c64',
        width: '100vw',
        zIndex:-10,
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'center',
        alignContent: 'center',
        position: 'absolute',
        top: 0,
        left: 0
    },
    bottom: {
        color: 'white',
          background: '#2d3c64',
          position: 'absolute',
          top: '100vh',
          width: '100vw'
      }
   
  }));

export default function MainContainer() {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Hero />
            <div className={classes.bottom}>
                <Video id={'documentary'}/>
                <OralHistory id={'history'}/>
                <Podcast />
                <ImageTrio band={4}/>
                <Civics />
                <ImageTrio band={2}/>
                <ProjectOverview id={'overview'} />
                <Commisioners />
                <StaffAdvisors />
                <ProjectSponsors />
                <ImageTrio band={3}/>
                <Team />
                <AWSTFooter />
           </div>
        </div>
    )
}
