import { createTheme }  from '@material-ui/core/styles'
const theme = createTheme({
    palette: {
        primary: {
          light: 'rgba(0,0,0,.3)',
          main: 'rgba(0,0,0,.6)',
          dark: 'rgba(0,0,0,.8)',
          contrastText: '#000000'
        },
        secondary: {
          light: '#ffffff',
          main: '#ededed',
          dark: '#bbbbbb',
          contrastText: '#ffffff',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
      },
      typography: {
        // fontFamily: "Times New Roman"
        
      },
     
})
export default theme