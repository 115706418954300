import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import Text from './BioCard/Text'

const data = {
    text2: 'The “Are We Safer Today?” project will also produce a civics curriculum and learning app in association the Center for Representative Government at Indiana University,  Funded by the Library of Congress, the curriculum will be available nationwide through the Indiana University system and our partner, the National Educational Telecommunications Association.',
    text: 'For the first time comes detailed insights from the staff who crafted the 9/11 Commission Report. In this exclusive nine-episode experience, we take an inside look into the most comprehensive investigation conducted in U.S. History. A story of tenacious civic engagement, intentional bipartisan efforts, and awe-inspiring patriotism unfolds.\n'
}
const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',

    },
    header: {
        width: '100%',
        height: 'auto',
        fontSize: '34px',
        paddingTop: '6vw',
        padding: '2vw',
        textAlign:'center',
    },
    textContainer: {
        width: '60vw',
        margin: 'auto',
        fontSize: '22px',
        paddingBottom: '3vh'
    },
    vidWrapper: {
        width: '60vw'
        
    },
    [theme.breakpoints.down('sm')]: {
    vidWrapper: {
        width: '90vw'
    },
    }
}
))

export default function Podcast() {
    const classes = useStyles()
    return (
        <div id={'podcast'} className={classes.container}>
        <div className={classes.header}>Are We Safer Today? Podcast</div>
        <div>
            <Text theme={classes.textContainer}>{data.text}</Text>
            <div className={classes.vidWrapper}>
                <ReactPlayer width="100%" url="https://www.youtube.com/watch?v=sh1giJkyrGI"/>
                </div>
        </div>
        
    </div>
    )
}
