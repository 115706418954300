import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

let sponsors = [
    'The Carnegie Corporation of New York',
    'Paul and Cindy Simon Skjodt & the Samerian Foundation',
    'Indiana University',
    'Indiana University Center for Representative Government',
    'Merrill, a Bank of America Company',
    'Pacers Sports and Entertainment',
    'The David & Lucile Packard Foundation',
    'The Lilly Endowment',
    'Drew University',
    'Eli Lilly & Company',
    'Kean University',
    'Mayer Brown',
    'Monolith Materials',
    'Mr. & Mrs. Michael McGavick in memory of Hon. Slade Gorton',
    'Pete & Janell Yonkman',
    'The Robert Wood Johnson Foundation',
    'The Ball Corporation',
    'Aramark',
    'The Lacy Foundation',
    'Greg Hahn',
    'Bill and Penny Paraskevas',
    'The Family of Slade Gorton',
    'Joan and Nathaniel Gorton',
    'Keller Macaluso',
    'Marketing Informatics',
    'Naptown Media',
]

const useStyles = makeStyles((theme) => ({
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'center',
    width: '100vw',
    header: {
        
        width: '100%',
        height: 'auto',
        fontSize: '34px',
        paddingTop: '4vw',
        padding: '2vw',
        textAlign:'center',
    },
    textContainer: {
        width: '60vw',
        margin: 'auto',
        fontSize: '22px',
        textAlign:'center',
    },
    list: {
        // padding: 0,
        marginTop: '.4vw',
        marginBottom: '.4vw'
    },
    text: {
        fontSize: '12px',
        textAlign:'center',
    },
   
}))

export default function ProjectSponsors() {
    const classes = useStyles()
    return (
        <div id={'sponsors'} className={classes.container}>
        <div className={classes.header}>Supported By</div>
            <div className={classes.textContainer}>
            {
                sponsors.map((sponsor, index) => {
                    return (
                        <p key={index} className={classes.list}>{sponsor}</p>
                    )
                })
            }
            </div>
        </div>
    )
}
