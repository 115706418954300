import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

let sponsors = [
    'Thomas H. Kean, Chair',
    'Lee H. Hamilton, Vice-Chair',
    'Bob Kerrey',
    'Fred Fielding',
    'James Thompson*',
    'Jamie Gorelick',
    'John Lehman',
    'Richard Ben-Veniste',
    'Slade Gorton*',
    'Tim Roemer',
]

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',

    },
    header: {
        width: '100%',
        fontSize: '34px',
        padding: '4vh',
        textAlign:'center',
    },
    textContainer: {
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',
        width: '70vw',
        margin: 'auto',
        fontSize: '22px',
    },
    list: {
        padding: 0,
        marginTop: '.4vw',
        marginBottom: '.4vw'
    },
    text: {
        fontSize: '16px',
        textAlign:'center',
        maxWidth: '50%',
        margin: 'auto'
    }
}   ))
export default function Commisioners() {
    const classes = useStyles()
    return (
        <div id={'members'} className={classes.container}>
            <div className={classes.header}>The 9/11 Commission Members</div>
                <div className={classes.textContainer}>
                {
                    sponsors.map((sponsor, i) => {
                        return (
                            <p key={i} className={classes.list}>{sponsor}</p>
                        )
                    })
                }
            </div>
            <div className={classes.text}>* Members of the Commission who were participating in the project but who died in 2020. We honor their memory and appreciate their contributions.
            </div>
        </div>
    )
}
