import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
// import Text from '../BioCard/Text'
// let text = `The members of the 9/11 Commission reunite to reflect on the largest investigation in US history and to assess our nation’s progress over the last 20 years.  The film will air on public television in the fall of 2022.`

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',
        width: '100vw',
        height: 'auto',
        padding: '1vw'

    },
    header: {
        width: '100%',
        height: 'auto',
        fontSize: '34px',
        paddingTop: '1vw',
        padding: '2vw',
        textAlign:'center',
    },
    textContainer: {
        width: '60vw',
        margin: 'auto',
        fontSize: '22px',
        paddingBottom: '3vh'
    },
   
    vidWrapper: {
        width: '60vw'
    },
    [theme.breakpoints.down('sm')]: {
    vidWrapper: {
        width: '90vw'
    },
    }}
    
  
))

export default function VideoCard() {
    const classes = useStyles()
    return (
        <div id={'overview'} className={classes.container}> 
            <div className={classes.header}>Project Overview</div>
            {/* <Text theme={classes.textContainer}>{text}</Text> */}
             <div className={classes.vidWrapper}>
                <ReactPlayer width="100%"  url="https://youtu.be/ngfqhE_6m0E"/>
             </div>
        </div>
    )
}
