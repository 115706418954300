import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import Text from './BioCard/Text'

const data = {
    text: '"Are We Safer Today?" will be the only chronicles of the memories and observations of four dozen of the leading officials who were part of the 9/11 Commission saga. The final recordings will be housed at the Library of Congress for future generations.'
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',

    },
    header: {
        width: '100%',
        height: 'auto',
        fontSize: '34px',
        paddingTop: '6vw',
        padding: '2vw',
        textAlign:'center',
    },
    textContainer: {
        width: '60vw',
        margin: 'auto',
        fontSize: '22px',
        paddingBottom: '3vh'
    },
    vidWrapper: {
        width: '60vw'
    },
    [theme.breakpoints.down('sm')]: {
    vidWrapper: {
        width: '90vw'
    },
    }
}   ))

export default function OralHistory() {
    const classes = useStyles()
    return (
        <div id={'history'} className={classes.container}>
            <div className={classes.header}>Oral History Trailer</div>
            <Text theme={classes.textContainer}>{data.text}</Text>
                <div className={classes.vidWrapper}>
                <ReactPlayer width="100%" url="https://www.youtube.com/watch?v=bWbCqqziDiw"/>
                </div>
        </div>
    )
}
